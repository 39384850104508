import axios from "axios";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

export const getApiData = async () => {
  try {
    const res = await API.get("/portfolio");
    return res;
  } catch (error) {
    return error.response;
  }
};

export const getTrendData = async () => {
  try {
    const res = await API.get("/trendlab");
    return res;
  } catch (error) {
    return error.response;
  }
};
