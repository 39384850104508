import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logoImg from "../../assets/images/logo.png";
import logo_lukasiewicz from "../../assets/images/logo_lukasiewicz.svg";

export const PageHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col md:flex-row items-center justify-between gap-10 pt-10 head">
      <div
        className="flex gap-5 items-center text-white text-2xl cursor-pointer"
        onClick={() => navigate("/")}
        style={{ fontFamily: "Montserrat" }}
      >
        <img src={logoImg} alt="logImg" className="w-24" />
        JAWAID LAB
      </div>
      <div
        className="lg:mt-0 cursor-pointer"
        onClick={() => window.open("https://lukasiewicz.gov.pl/", "_blank")}
      >
        <img src={logo_lukasiewicz} alt="logo_lukasiewicz" className="w-16" />
      </div>
    </div>
  );
};
