import React from "react";
import { HeaderPage } from "../portfolio/HeaderPage";
import { Typography } from "@mui/material";
import { convertImagePathToUrl } from "../../utils";
import { TrendHamburger } from "./TrendHamburger";
import { TrendButtons } from "./TrendButtons";

export const TrendIntroduction = ({ trendHero, trendAbout }) => {
  const formatModalDescription = (description) =>
    description?.replace(/(\r\n|\r|\n)/g, "<br>");

  const formatAboutDescription = (description) => {
    if (!description) return "";

    // Replace "click here" with a blue hyperlink that triggers scrollToThemes
    let formattedDescription = description.replace(
      /"click here"/gi,
      '<a class="text-blue-400 underline cursor-pointer hover:text-blue-700" onclick="scrollToThemes()">Click Here</a>'
    );

    // Replace newlines with <br>
    formattedDescription = formattedDescription.replace(
      /(\r\n|\r|\n)/g,
      "<br>"
    );

    // Wrap ordered list items (starting with a number) with a hanging indent using a div
    formattedDescription = formattedDescription.replace(
      /(\d+\.\s)([^\n<]+)(<br>)/g,
      '<div style="margin-left: 20px; text-indent: -14px;">$1$2</div>$3'
    );

    return formattedDescription;
  };

  // Ensure scrollToThemes is globally accessible
  window.scrollToThemes = () => {
    const element = document.getElementById("TREND Themes");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const menu2 = [
    { id: 0, title: "About" },
    { id: 1, title: "TREND Team" },
    { id: 2, title: "TREND Themes" },
    { id: 3, title: "TREND Trends" },
    { id: 4, title: "TREND Treats" },
  ];

  const trendHeadbtn = [
    {
      id: 0,
      title: "Contact Us",
    },
  ];

  if (trendHero.length === 0) {
    return;
  }

  const trendButtonsFromJson = trendHero.details?.flatMap(
    (detail) =>
      detail.buttons?.map((button) => ({
        id: button.button_id,
        title: button.label,
        action: button.action,
      })) || []
  );

  return (
    <>
      {/* Hero Section */}
      <div id="hero" className="z-0 trendBgImg1">
        <div
          className="hidden lg:block"
          style={{ position: "", top: 0, zIndex: 50 }}
        >
          <HeaderPage menu1={menu2} headbutton1={trendHeadbtn} />
        </div>
        <div className="xl:hidden">
          <TrendHamburger />
        </div>

        <div
          className="flex items-center justify-between mx-auto text-white 2xl:flex-row"
          style={{ maxWidth: "80%", minHeight: "100vh" }} // Ensure full height for the Hero section
        >
          <div className="w-1/2">
            <Typography
              className="mt-[5rem] font-semibold text-5xl"
              style={{
                fontFamily: "Montserrat",
              }}
            >
              {trendHero?.section_heading}
            </Typography>
            {trendHero?.details?.map((detail) => (
              <>
                {detail?.singleArray?.map((det, index) => (
                  <>
                    {det?.key === "sub-heading" && (
                      <Typography
                        key={index}
                        className="mt-5"
                        style={{
                          fontSize: 25,
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                        }}
                      >
                        {det?.sub_heading}
                      </Typography>
                    )}
                    <Typography
                      key={index + "desc"}
                      className="mx-auto my-5"
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        fontFamily: "Montserrat",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: formatModalDescription(det?.sub_heading_desc), // Correctly using dangerouslySetInnerHTML here
                      }}
                    ></Typography>
                  </>
                ))}
              </>
            ))}

            <div className="pb-20">
              <TrendButtons Tbutton1={trendButtonsFromJson} />
            </div>
          </div>
          <div className="hidden py-20 lg:block md:w-1/2 2xl:px-20">
            {trendHero.details?.[0].assets?.[0].videos?.map((video, index) => (
              <iframe
                key={index}
                className="w-[568px] my-4 rounded-lg h-96"
                src={video?.video_url}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            ))}
          </div>
        </div>
      </div>

      {/* About Section */}
      <div id="About" className="z-0 flex flex-row trendBgImg2 py-10">
        {" "}
        {/* Increased padding here */}
        <div
          className="flex flex-row justify-start mx-auto 2xl:flex-row max-h-screen"
          style={{ maxWidth: "80%" }}
        >
          {trendAbout?.details?.map((detail) => (
            <>
              {detail?.singleArray?.map((det) => (
                <>
                  {det?.key === "image" && (
                    <div className="hidden xl:w-1/2 lg:flex justify-center items-center">
                      <img
                        className="object-cover"
                        src={convertImagePathToUrl(det?.image_url)}
                        alt="Trend"
                      />
                    </div>
                  )}
                </>
              ))}

              <div className="text-white xl:w-1/2 flex flex-col justify-center">
                <Typography
                  className="font-bold"
                  style={{
                    fontFamily: "Montserrat",
                  }}
                >
                  {trendAbout?.section_heading}
                </Typography>
                {detail?.singleArray?.map((det, ind) => (
                  <>
                    {det?.key === "sub-heading" && (
                      <>
                        <Typography
                          key={ind}
                          className="mt-3 text-4xl font-bold"
                          style={{
                            fontFamily: "Montserrat",
                          }}
                        >
                          {det?.sub_heading}
                        </Typography>
                        <Typography
                          className="my-10 text-sm"
                          style={{ fontSize: 14, fontFamily: "Montserrat" }}
                          dangerouslySetInnerHTML={{
                            __html: formatAboutDescription(
                              det?.sub_heading_desc
                            ), // Correctly using dangerouslySetInnerHTML here
                          }}
                        />
                      </>
                    )}
                  </>
                ))}
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};
