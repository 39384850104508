import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import ImgInstagram from "../assets/images/ImgInsta.svg";
import ImgLinkedIn from "../assets/images/ImgLinkedin.svg";
import ImgTwitter from "../assets/images/Imgtweet.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import { HomeButtons } from "./landingPage/HomeButtons";

export const Footer = ({ color }) => {
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      offset: 500,
    });

    // Fetch footer data using Axios
    const fetchFooterData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/footer`
        );
        setFooterData(response.data);
      } catch (error) {
        console.error("Error fetching footer data:", error);
      }
    };

    fetchFooterData();
  }, []);

  return (
    <div
      className="flex flex-col items-center justify-center h-auto lg:flex-row"
      style={{ background: color }} // Use the color prop here
    >
      {footerData?.sections.map((section, index) => (
        <div
          key={index}
          className="flex flex-col items-center justify-center lg:flex-row lg:pl-10"
        >
          <div className="w-1/2 mt-16 foot text-white">
            <div className="md:w-full">
              <Typography
                style={{
                  fontWeight: "bolder",
                  fontSize: 42,
                  fontFamily: "Montserrat",
                }}
              >
                {section.section_heading}
              </Typography>
            </div>
            <Typography style={{ fontFamily: "Montserrat" }}>
              {section.section_description}
            </Typography>
            <div className="flex flex-row mt-10 mb-5 md:w-full">
              {section.details[0]?.buttons.slice(0, 3).map((button, idx) => (
                <a
                  key={idx}
                  href={button.action}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={
                      button.label === "Instagram"
                        ? ImgInstagram
                        : button.label === "LinkedIn"
                        ? ImgLinkedIn
                        : button.label === "x"
                        ? ImgTwitter
                        : ""
                    }
                    className="mr-5"
                    alt={button.label}
                  />
                </a>
              ))}
            </div>
          </div>

          <div className="flex justify-center w-1/2 mx-10 md:flex xl:ml-80">
            {section.details[0]?.buttons.slice(3).map((button, idx) => (
              <div
                key={idx}
                className={`mr-10 flex justify-center items-center pr-4 cursor-pointer footer-img-style text-sm ${
                  idx === 0 ? "mail" : "utube"
                }`}
              >
                <div className="text-center text-white ">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={button.action}
                  >
                    {button.label}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div
        className="mb-10 md:hidden"
        // data-aos="fade-in"
        // data-aos-delay="500"
      >
        <HomeButtons />
      </div>
    </div>
  );
};
