import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import polygonSvg from "../../assets/images/Polygon.svg";
import { convertImagePathToUrl } from "../../utils";

import React from "react";
import { Typography } from "@mui/material";

export const AuthorCrousal = ({ author }) => {
  const subHeadings = author?.details?.[0]?.sub_headings || [];
  // console.log("Sub-Headings: ", subHeadings);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  console.log("Sub-Headings: ", subHeadings[0]?.sub_heading_bg_image);

  return (
    <div className="py-10">
      <Slider {...settings}>
        {subHeadings.map((value, index) => (
          <div className="flex justify-center" key={index}>
            <div
              className="relative inline-block 2xl:my-10"
              style={{
                backgroundImage: value.sub_heading_bg_image
                  ? `url(${convertImagePathToUrl(value?.sub_heading_bg_image)})`
                  : `url(${polygonSvg})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "375px",
                width: "400px",
              }}
            >
              <Typography
                className="mx-8 mt-40 text-center text-white text-xl font-bold"
                style={{
                  fontFamily: "Montserrat",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  backdropFilter: "blur(10px)",
                  WebkitBackdropFilter: "blur(10px)",
                }}
              >
                {value?.sub_heading}
              </Typography>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
