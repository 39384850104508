import React from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../landingPage/PageHeader";
import { Typography } from "@mui/material";
import { Footer } from "../Footer";

export const HomePage = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="z-0 loginImg">
        <PageHeader />
        <div
          style={{ height: "100vh" }}
          className="flex flex-col items-center justify-center mt-[2rem] 2xl:mt-[2rem] md:flex-row imghide"
        >
          <div
            className="img1 cursor-pointer"
            onClick={() => handleNavigation("/portfolio")}
          >
            <div className="flex flex-col h-full">
              <div className="hidden lg:block" style={{ height: "70%" }} />
              <div
                className="hidden md:block lg:hidden"
                style={{ height: "60%" }}
              />
              <div className="md:hidden" style={{ height: "65%" }} />
              <div className="text-center sm:mr-20 md:mr-0 px-5 xl:px-0 xl:mr-20">
                <Typography
                  className="text-white text-xl font-bold"
                  style={{ fontFamily: "Montserrat" }}
                >
                  Dr. Ali Jawaid
                </Typography>
                <Typography
                  className="text-white"
                  style={{ fontFamily: "Montserrat", fontSize: 15 }}
                >
                  Neuroscience Researcher & Medical Doctor
                </Typography>
              </div>
            </div>
          </div>
          <div
            className="img2 cursor-pointer"
            onClick={() => handleNavigation("/trendlab")}
          >
            <div className="flex flex-col h-full">
              <div className="hidden lg:block" style={{ height: "70%" }} />
              <div
                className="hidden md:block lg:hidden"
                style={{ height: "60%" }}
              />
              <div className="md:hidden" style={{ height: "65%" }} />
              <div className="text-center sm:mr-20 md:mr-0 px-5 xl:px-0 xl:ml-8">
                <Typography
                  className="text-white font-bold text-xl"
                  style={{ fontFamily: "Montserrat" }}
                >
                  TREND Lab
                </Typography>
                <Typography
                  className="text-white"
                  style={{ fontFamily: "Montserrat", fontSize: 15 }}
                >
                  Laboratory of Translational Research in Neuropsychiatric
                  Disorders
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer color="#0C1C2B" />
    </div>
  );
};
