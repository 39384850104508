import { Button, Typography } from "@mui/material";
import logoImg from "../../assets/images/logo.png";

import React from "react";
import { useNavigate } from "react-router";

const menu = [
  { id: 0, title: "About" },
  { id: 1, title: "The Scientist" },
  { id: 2, title: "The Educator" },
  { id: 3, title: "The Humanitarian" },
  { id: 4, title: "The Author" },
  { id: 5, title: "The Buzz" },
];
const headbutton = [
  {
    id: 0,
    title: "Lets Connect",
  },
];
export const HeaderPage = ({ menu1 = menu, headbutton1 = headbutton }) => {
  const handleClick = (title) => {
    // setOpen(true);
    const element = document.getElementById(title);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const navigate = useNavigate();
  return (
    <div className="flex flex-row items-center justify-between pt-10 head">
      {/* upon clicking on this image navigate to "/" route */}
      <div
        className="flex flex-col items-center text-center text-white text-2xl cursor-pointer"
        style={{ fontFamily: "Montserrat" }}
        onClick={() => navigate("/")}
      >
        <img src={logoImg} alt="logo" className="w-24" />
        JAWAID LAB
      </div>
      <div className="flex flex-row ">
        {menu1.map((value) => (
          <Typography
            className="justify-center mx-3 text-white cursor-pointer 2xl:mx-10"
            onClick={() => handleClick(value.title)}
            style={{ fontFamily: "Montserrat" }}
          >
            {value.title}{" "}
          </Typography>
        ))}
      </div>
      <div className="Button lg:mt-0 ">
        {headbutton1.map((value) => (
          <Button
            style={{ backgroundColor: "white" }}
            className="h-10 rounded-lg w-36"
          >
            <Typography
              className="text-black"
              style={{ fontSize: 14 }}
              onClick={() => handleClick(value.title)}
            >
              {value.title}
            </Typography>
          </Button>
        ))}
      </div>
    </div>
  );
};
