import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import Aos from "aos";
import "aos/dist/aos.css";
import ThemePolygon from "../../assets/images/ThemePolygon.png";
import { convertImagePathToUrl } from "../../utils";

export const TrendThemesPolygon = ({ item, trendThemes }) => {
  const formatModalDescription = (description) =>
    description?.replace(/(\r\n|\r|\n)/g, "<br>");

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      offset: 500,
    });
  }, []);
  return (
    <div className="relative" data-aos="fade-in" data-aos-delay="500">
      <img
        src={convertImagePathToUrl(item?.img) ?? ThemePolygon}
        alt="Theme Polygon"
        className="justify-center roundImg"
        style={{
          width: item?.img ? "400px" : "350px",
        }}
      />
      <div
        style={{
          width: "345px",
          height: "920px",
        }}
        className="mx-8 my-24 bg-white shadow-lg shadow-black rounded-xl mx:0 2xl:mx-20 mt-26 pb-14"
      >
        <Typography
          className="px-5 pt-[20rem] pl-5 pr-5 text-center"
          fontStyle={{
            fontWeight: 600,
            fontSize: "18px",
            fontFamily: "Montserrat",
          }}
        >
          {item?.title}
        </Typography>
        <Typography
          className="px-5 pt-5 text-center"
          style={{
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "Montserrat",
          }}
          dangerouslySetInnerHTML={{
            __html: formatModalDescription(item?.desc), // Correctly using dangerouslySetInnerHTML here
          }}
        />
      </div>
    </div>
  );
};
