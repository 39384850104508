import React from "react";
import { SupportHome } from "../components/support/SupportHome";

export const SupportUs = () => {
  return (
    <div>
      <SupportHome />
    </div>
  );
};
