import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import YImg from "../../assets/images/VectorMail.svg";
import MImg from "../../assets/images/VectorYtube.svg";
import "../../assets/css/scientist.css";

const button1 = [
  { id: 0, img: MImg, title: "a.jawaid@nencki.edu.pl" },
  { id: 0, img: YImg, title: "youtube.com/@alijawaid" },
];
export const HomeButtons = () => {
  const [open, setOpen] = useState(false);

  const handleClick = (title) => {
    setOpen(true);
  };
  return (
    <div className="">
      {button1.map((value, index) => (
        <Button
          key={index}
          className="flex justify-center h-20 p-0 my-5 text-white rounded-lg w-80"
          style={{ background: "#1E3F52" }}
          onClick={() => handleClick(value.title)}
        >
          <img className="p-0 mx-3" src={value.img} alt="img" />
          <Typography>{value.title} </Typography>
        </Button>
      ))}
    </div>
  );
};
