import React, { useEffect } from "react";
// import { Typography } from "@mui/material";
// import ScientistImg7 from "../../assets/images/ScientistImg7.png";
// import ScientistImg3 from "../../assets/images/ScientistImg3.png";
import { TrendConsultancyForm } from "./TrendConsultancyForm";
import AOS from "aos";
import "aos/dist/aos.css";

export const TrendConsultancy = React.forwardRef((_, ref) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 500, // Adjust offset if necessary
    });
  }, []);

  return (
    <div ref={ref}>
      <div className=" ConsultancyBg">
        {/* <div className="flex justify-between h-[700px]" data-aos="fade-right">
          <div
            className="justify-end hidden lg:flex"
            style={{ flex: 1, background: "#EBEEF3" }}
          >
            <img
              src={ScientistImg7}
              className="h-full mr-auto leftArrowStyle"
              alt=""
              style={{ background: "#EBEEF3" }}
            />
          </div>
          <div
            className="flex flex-col items-center "
            style={{ flex: 3, background: "#EBEEF3" }}
          >
            <div
              className="pt-[10rem] mx-20 lg:mx-0"
              style={{ maxWidth: "580px" }}
            >
              <Typography
                className=""
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 36,
                  fontWeight: 400,
                }}
              >
                Consultancy and Contact
              </Typography>

              <Typography
                className="mt-10 text-start "
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                Dr. Ali Jawaid is available to deliver lectures and medical
                seminars, interested in scientific collaborations and
                consultancy, and dedicated to advocacy in academia, epigenetics,
                translational research, MD-PhD studies, neuroscience, and
                childhood trauma.
              </Typography>
              <Typography
                className="mt-10 text-start"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                If you wish to reach out to Ali for any of these purposes or
                have questions and feedback about his research, would like to
                invite him for an interview, or seek his mentorship, please use
                the form below to get in touch:
              </Typography>
            </div>
          </div>
          <div className="hidden pr-10 lg:block" style={{ flex: 1 }}>
            <img
              src={ScientistImg3}
              className="h-full mr-auto leftArrowStyle"
              alt=""
            />
          </div>
        </div> */}

        <TrendConsultancyForm />
      </div>
    </div>
  );
});
