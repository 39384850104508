import React, { useEffect, useState } from "react";
import { TrendIntroduction } from "./TrendIntroduction";
import { Team } from "./Team";
import { TrendTreats } from "./TrendTreats";
import { TrendConsultancy } from "./TrendConsultancy";
import { TrendThemes } from "./TrendThemes";
import { getTrendData } from "../../axios";
import { Footer } from "../Footer";

export const TrendHomePage = () => {
  const [trendHero, settrendHero] = useState([]);
  const [trendAbout, settrendAbout] = useState([]);
  const [trendThemes, settrendThemes] = useState([]);
  const [trendTeams, settrendTeams] = useState([]);
  const [trendTrends, settrendTrends] = useState([]);
  const [trendNews, settrendNews] = useState([]);
  const [trendTreats, settrendTreats] = useState([]);
  const [trendSeminar, settrendSeminar] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const parseSectionDetails = (details) => {
    return details.map((detail) => {
      let singleArray = [
        ...detail.sub_headings.map((subHeading) => ({
          ...subHeading,
          key: "sub-heading",
        })),
        ...detail.descriptions.map((desc) => ({ ...desc, key: "desc" })),
      ];

      // Extract and store images in the singleArray
      let imageObjects = [];
      detail.assets.forEach((asset) => {
        asset.images.forEach((image) => {
          imageObjects.push({
            key: "image",
            asset_id: asset.asset_id,
            image_url: image.image_url,
            image_position: asset.image_position || null, // Include image position if needed
          });
        });
      });

      singleArray = [...singleArray, ...imageObjects];

      // Sorting the combined array by position
      singleArray = singleArray.sort((a, b) => {
        let positionA;
        let positionB;

        if (a.key === "sub-heading") positionA = a.sub_heading_position;
        if (a.key === "desc") positionA = a.description_position;
        // if (a.key === "btt") positionA = a.button_position;
        if (a.key === "image") positionA = a.image_position;

        if (b.key === "sub-heading") positionB = b.sub_heading_position;
        if (b.key === "desc") positionB = b.description_position;
        // if (b.key === "btt") positionB = b.button_position;
        if (b.key === "image") positionB = b.image_position;

        if (positionA === positionB) return 0;
        return positionA > positionB ? 1 : -1;
      });

      detail.singleArray = singleArray;
      return detail;
    });
  };

  const getData = async () => {
    try {
      const res = await getTrendData();
      // console.log("TREND DATA: ",res);

      if (res.status >= 200 && res.status < 300) {
        // console.log(res.data);
        res.data.sections.forEach((section) => {
          switch (section.section_title) {
            case "Hero":
              section.details = parseSectionDetails(section.details);
              settrendHero(section);
              // console.log("hi there", trendHero);
              break;
            case "About":
              section.details = parseSectionDetails(section.details);
              settrendAbout(section);
              break;
            case "TREND Team":
              section.details = parseSectionDetails(section.details);
              settrendTeams(section);
              // console.log("TREND Teams Data: ", trendTeams);
              break;
            case "TREND Themes":
              section.details = parseSectionDetails(section.details);
              settrendThemes(section);
              break;
            case "TREND Trends":
              section.details = parseSectionDetails(section.details);
              settrendTrends(section);
              // console.log("Trend trends", trendTrends);
              break;
            case "TREND News":
              section.details = parseSectionDetails(section.details);
              settrendNews(section);
              break;
            case "TREND Treats":
              section.details = parseSectionDetails(section.details);
              settrendTreats(section);
              break;
            case "TREND Seminars":
              section.details = parseSectionDetails(section.details);
              settrendSeminar(section);
              break;

            default:
              console.log(`Unknown section: ${section.section_title}`);
          }
        });
        setLoading(false); // Data has finished loading
      } else {
        console.error("Failed to fetch data:", res.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false); // Ensure we stop loading even if there's an error
    }
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {!loading && (
        <div>
          <TrendIntroduction trendHero={trendHero} trendAbout={trendAbout} />
          <Team trendTeams={trendTeams} />
          <TrendThemes
            trendThemes={trendThemes}
            trendTrends={trendTrends}
            trendNews={trendNews}
          />
          <TrendTreats
            trendTreats={trendTreats?.details}
            trendSeminar={trendSeminar?.details}
          />
          <TrendConsultancy />
          <Footer color="#0C2D37" />
        </div>
      )}
    </>
  );
};
