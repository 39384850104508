import { Button, Typography } from "@mui/material";
import logoImg from "../../assets/images/logo.png";
import React from "react";
import { useNavigate } from "react-router";

export const SupportHeader = ({ menu1, headbutton1 }) => {
  const navigate = useNavigate();

  // Function to handle menu item clicks and navigate to respective routes
  const handleNavigate = (title) => {
    if (title === "Home") {
      navigate("/");
    } else if (title === "Portfolio") {
      navigate("/portfolio");
    } else if (title === "TREND Lab") {
      navigate("/trendlab");
    }
  };
  const handleConnectClick = () => {
    // Navigate to the /portfolio route
    navigate("/portfolio");

    // Scroll to the element with id "LetsConnect" after navigating
    setTimeout(() => {
      const targetElement = document.getElementById("Lets Connect");
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100); // Delay to ensure navigation is completed before scrolling
  };

  return (
    <div className="flex flex-row items-center justify-between pt-10 head">
      {/* Clicking on logo navigates to "/" */}
      <div
        className="flex flex-col items-center text-center text-white text-2xl cursor-pointer"
        style={{ fontFamily: "Montserrat" }}
        onClick={() => navigate("/")}
      >
        <img src={logoImg} alt="logo" className="w-24" />
        JAWAID LAB
      </div>

      <div className="flex flex-row ">
        {menu1.map((value) => (
          <Typography
            key={value.id}
            className="justify-center mx-3 text-white cursor-pointer 2xl:mx-10"
            style={{ fontFamily: "Montserrat" }}
            onClick={() => handleNavigate(value.title)} // Call navigation function here
          >
            {value.title}
          </Typography>
        ))}
      </div>

      <div className="Button lg:mt-0 ">
        {headbutton1.map((value) => (
          <Button
            key={value.id}
            style={{ backgroundColor: "white" }}
            className="h-10 rounded-lg w-36"
            onClick={handleConnectClick}
          >
            <Typography className="text-black" style={{ fontSize: 14 }}>
              {value.title}
            </Typography>
          </Button>
        ))}
      </div>
    </div>
  );
};
