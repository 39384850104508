import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router";

const smenu = [
  { id: 0, title: "Home" },
  { id: 1, title: "Portfolio" },
  { id: 2, title: "TREND Lab" },
];

export const SupportHamburger = () => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const handleNavigate = (title) => {
    if (title === "Home") {
      navigate("/");
    } else if (title === "Portfolio") {
      navigate("/portfolio");
    } else if (title === "TREND Lab") {
      navigate("/trendlab");
    }
  };
  const DrawerList = (
    <Box
      sx={{ backgroundColor: "#071720", width: 250, height: "100%" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <List className="text-white">
        {smenu.map((text, index) => (
          <ListItem key={text.id} disablePadding>
            <ListItemButton
              onClick={() => {
                handleNavigate(text.title);
              }}
            >
              <ListItemText primary={text.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon sx={{ color: "#FAFAFA" }} />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};
