import React from "react";
import { TrendHomePage } from "../components/trendLab/TrendHomePage";

export const TrendLab = () => {
  return (
    <div>
      <TrendHomePage />
    </div>
  );
};
