import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router";

const trendMenu = [
  { id: 0, title: "Home", section_id: "/" },
  { id: 1, title: "TREND Team", section_id: "TREND Team" },
  { id: 2, title: "TREND Themes", section_id: "TREND Themes" },
  { id: 3, title: "TREND Trends", section_id: "TREND Trends" },
  { id: 4, title: "TREND Treats", section_id: "TREND Treats" },
];

export const TrendHamburger = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const handleListItemClick = (section_id) => {
    if (section_id === "/") {
      navigate("/"); // Navigate to home route
    } else {
      const element = document.getElementById(section_id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 300); // Delay to allow drawer to close
      } else {
        console.error("Element not found for section: ", section_id);
      }
    }
  };

  const DrawerList = (
    <Box
      sx={{ backgroundColor: "#008A85", width: 250, height: "100%" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <List className="text-white">
        {trendMenu.map((text, index) => (
          <ListItem key={text.id} disablePadding>
            <ListItemButton
              onClick={() => handleListItemClick(text.section_id)}
            >
              <ListItemText primary={text.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon sx={{ color: "#37A4A5" }} />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};
