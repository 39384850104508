import { Typography, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";

export const TrendButtons = ({ Tbutton1 }) => {
  const navigate = useNavigate();

  const handleClick = (value) => {
    if (value.title === "Contribute") {
      // Redirect to the backend route
      window.location.href = process.env.REACT_APP_API_URL;
    } else if (value.title === "Support Us") {
      navigate(value.action);
    } else {
      const element = document.getElementById(value.action);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className="flex flex-col items-center xl:flex-row">
      {Tbutton1.slice() // Create a shallow copy of the array
        .sort((a, b) => b.id - a.id) // Sort in descending order based on the title
        .map((value) => (
          <Button
            onClick={() => handleClick(value)}
            key={value.title}
            style={{
              borderColor: "#FFFFFF",
              backgroundColor:
                value.title === "Support Us" ? "#37A4A5" : "transparent",
            }}
            className="flex justify-center w-40 h-12 mt-5 mr-5 text-white border-2 border-solid rounded-lg trendButton 2xl:w-44"
          >
            <Typography
              className="text-[14px]"
              style={{ textTransform: "none" }}
            >
              {value.title}
            </Typography>
          </Button>
        ))}
    </div>
  );
};
